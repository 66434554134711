import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'dmc-ng-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss',
})
export class BadgeComponent {
  @Input() value = '';
  @Input() width = 40;
  @Input() color: 'primary' | 'secondary' = 'primary';
  @Input() border?: 'white' | 'primary';
}
